.App {
  text-align: center;
}

html,
body {
  font-family: "Montserrat", "Cairo", sans-serif !important;
  font-weight: 400 !important ;
  padding: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-select-selector {
  min-height: 52px !important;
  padding: 10px !important;
}

.ant-select-selection-search-input {
  margin-top: 5px !important;
}

.PhoneInputInput {
  padding-top: 13px;
  padding-bottom: 13px;
  border: 1px solid #dadada;
  border-radius: 1px;
  outline-color: #40a9ff7a !important;
}

.PhoneInputCountry {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 8px;
  padding-left: 8px;
  border: 1px solid #dadada;
  border-radius: 2px;
}

.bold {
  font-weight: bold !important;
}
